import './css/App.css';
import './css/wutang.css';
import { FaTwitter } from 'react-icons/fa';
import { FaTelegramPlane } from 'react-icons/fa';
import { FaEthereum } from 'react-icons/fa';
import { MdEmail } from 'react-icons/md';
import logo from './logo.png';


function App() {
  return (
    <div className="App">
      <div class="overlay">        
        <div class="overlay__inner">
          <img src={logo} alt="Logo" /> 
          <h1 class="overlay__title">
            I splash <span class="text-gradient text-glow">bravado</span>, <br/>fast cash <span class="text-gradient text-glow">aficionado</span>.
          </h1>
          <p class="overlay__description">
            <a href="https://etherscan.io/address/0x72862dd1b086e21c40f5b05b5b170a2233cc45bc">
                <FaEthereum />
                <strong>wutangdev.eth</strong>
            </a>
            <a href="mailto: contact@wutang.dev">
                <MdEmail />
                <strong>contact@wutang.dev</strong><br/>
            </a>
            <a href="https://twitter.com/wutangdev">
                <FaTwitter />
                <strong>@wutangdev</strong>
            </a>
            <a href="https://t.me/WutangDev">
                <FaTelegramPlane />
                <strong>@wutangdev</strong><br/>
            </a>
          </p>
        </div>
        </div>
    </div>
  );
}

export default App;
